<template>
  <div class="page" :class="{ 'has-breadcrumbs': displayBreadcrumbs }">
    <div v-if="displayBreadcrumbs" class="page__breadrumbs container">
      <pt-breadcrumbs />
    </div>
    <page-header-fallback v-if="!hasHeroBlock" centered actions-classes="has-marg-top-xl">
      {{ currentGrant.title }}
      <template v-if="currentGrant.apply_url" #cta>
        <pt-button type="is-primary" wide tag="a" :href="currentGrant.apply_url" target="_blank" rel="nofollow noopener"> Apply Now </pt-button>
      </template>
    </page-header-fallback>
    <div class="page__sections">
      <render-blocks v-if="currentGrant.sections" :sections="currentGrant.sections" />
    </div>
    <div v-if="currentGrant.apply_url" class="page__footer has-marg-top-xl has-marg-bottom-xl is-centered">
      <pt-button type="is-primary" wide tag="a" :href="currentGrant.apply_url" target="_blank" rel="nofollow noopener"> Apply Now </pt-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PtBreadcrumbs from '@/components/global/elements/PtBreadcrumbs.vue';
import PageHeaderFallback from '~/components/global/components/page/PageHeaderFallback.vue';
import PtButton from '@/components/global/elements/PtButton.vue';
import RenderBlocks from '~/components/content/RenderBlocks.vue';
import { useGrantsStore } from '~/stores/grants';

const grantsStore = useGrantsStore();
const currentGrant = computed(() => grantsStore.getCurrentGrant);

const displayBreadcrumbs = computed(() => {
  return !(!currentGrant.value || currentGrant.value.hide_breadcrumbs);
});

const sections = computed(() => {
  return Array.isArray(currentGrant.value.sections) ? currentGrant.value.sections : [];
});

const hasHeroBlock = computed(() => {
  if (!sections.value.length) {
    return false;
  }
  return ['block_hero', 'block_hero_cta', 'block_hero_simple'].includes(sections.value[0].type);
});
</script>
